import { useEffect } from "react";
import { PageTitle } from "../../../_metronic/layout/core";

const Invite = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/embed/v2.js';
        script.setAttribute('type', 'text/javascript');
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "23399414",
                    formId: "cb01355e-9366-4e62-bedf-5df516ff5f1d",
                    target: "#hubspotFormInvite"
                })
            }
        })
    }, []);
    return (
      <>
        <PageTitle breadcrumbs={[]}>
          INVITE
        </PageTitle>
        <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
          <div className='card-header pt-3 pb-2 d-flex align-items-center'>
            <h3 className='card-title align-items-start flex-row'>
              <span className='card-label fw-light fs-3 text-primary'>Invite</span>
            </h3>
          </div>

          <div className='card-body py-6'>
            <div id="hubspotFormInvite"></div>
          </div>
        </div>
      </>
    )
}

export {Invite}