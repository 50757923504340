import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Formik, Form, FormikHelpers} from 'formik'
import {ResetPasswordLinkAPI, requestCode} from '../../services/auth.service'
import InputField from '../input-field/input-field'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type FormValues = {
  email: string
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
})

const initialValues: FormValues = {
  email: '',
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const onSubmitHandler = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      setLoading(true)
      const data = await ResetPasswordLinkAPI(values.email)
      if (data.ResponseMetadata.HTTPStatusCode === 200) {
        setSuccessMessage('Please check your email to reset the password.')
        setErrorMessage('')
        helpers.resetForm()
      } else if (data.statusCode) {
        setErrorMessage(data.body || 'Invalid credentials entered')
        setSuccessMessage('')
      } else {
        setErrorMessage('Invalid credentials entered')
        setSuccessMessage('')
      }
    } catch (err) {
      setErrorMessage('Invalid credentials entered')
      setSuccessMessage('')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className=' d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='auth-container d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div className='mw-500px w-100 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto h-500px'>
            <div
              className='col-12'
              style={{alignContent: 'center', display: 'grid', height: '10%'}}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/ATR/images/MaestroX.png')}
                className='img-fluid'
              />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={forgotPasswordSchema}
              onSubmit={onSubmitHandler}
            >
              {({status, isSubmitting, isValid}) => (
                <Form className='form w-100 '>
                  <div className='my-10'>
                    <hr />
                  </div>

                  <div className='mb-6'>
                    <h1 className='text-center text-primary'>Forgot Password</h1>
                  </div>
                  {status ? (
                    <div className='mb-lg-5 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{status}</div>
                    </div>
                  ) : (
                    ''
                  )}

                  {successMessage && (
                    <div className='mb-lg-5 alert alert-success'>
                      <div className='alert-success font-weight-bold text-dark'>
                        {successMessage}
                      </div>
                    </div>
                  )}

                  {errorMessage && (
                    <div className='mb-lg-5 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{errorMessage}</div>
                    </div>
                  )}

                  <div className='fv-row mb-10'>
                    <InputField
                      label='Email'
                      labelClass='form-label fs-5 fw-bolder text-dark'
                      placeholder='Email'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                  </div>

                  <div className='text-center d-flex justify-content-center login-container-buttons'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={isSubmitting || !isValid || loading}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
