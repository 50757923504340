import {useLocation} from 'react-router-dom'
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Formik, Form, FormikHelpers} from 'formik'
import {UpdatePasswordAPI} from '../../services/auth.service'
import InputField from '../input-field/input-field'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type FormValues = {
  newpassword: string
  confirmpassword: string
}

const changePasswordSchema = Yup.object().shape({
  newpassword: Yup.string()
    .min(3, 'Minimum limit - 3 characters')
    .required('New password is required'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('newpassword'), null], 'Confirm password must match with the new password')
    .required('Confirm password is required'),
})

const initialValues: FormValues = {
  newpassword: '',
  confirmpassword: '',
}
const ChangePassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const userName = params.get('userName')
  const confirmationCode = params.get('confirmation_code')
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmitHandler = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      setLoading(true)

      if (userName !== null && confirmationCode !== null) {
        const data = await UpdatePasswordAPI(userName, confirmationCode, values.newpassword)

        if (data?.result?.ResponseMetadata?.HTTPStatusCode === 200) {
          setSuccessMessage(data.message)
          setErrorMessage('')
          helpers.resetForm()
          setTimeout(() => {
            setSuccessMessage('')
            navigate('/auth')
          }, 3000)
        } else if (data?.result?.statusCode === 500) {
          setErrorMessage('Invalid username or confirmation code')
          setSuccessMessage('')
        } else {
          setErrorMessage('Invalid username or confirmation code')
          setSuccessMessage('')
        }
      } else {
        setErrorMessage('Invalid username or confirmation code')
        setSuccessMessage('')
      }
    } catch (err) {
      console.error('Error updating password:', err)
      setErrorMessage('Invalid username or confirmation code')
      setSuccessMessage('')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className=' d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='auth-container d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div className='mw-500px w-100 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto h-600px'>
            <div
              className='col-12'
              style={{alignContent: 'center', display: 'grid', height: '10%'}}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/ATR/images/MaestroX.png')}
                className='img-fluid'
              />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={changePasswordSchema}
              onSubmit={onSubmitHandler}
            >
              {({status, isSubmitting, isValid}) => (
                <Form className='form w-100'>
                  <div className='my-10'>
                    <hr />
                  </div>

                  <div className='mb-6'>
                    <h1 className='text-center text-primary'>Change Password</h1>
                  </div>
                  {status ? (
                    <div className='mb-lg-5 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{status}</div>
                    </div>
                  ) : (
                    ''
                  )}

                  {successMessage && (
                    <div className='mb-lg-5 alert alert-success'>
                      <div className='alert-text font-weight-bold text-dark'>{successMessage}</div>
                    </div>
                  )}

                  {errorMessage && (
                    <div className='mb-lg-5 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{errorMessage}</div>
                    </div>
                  )}

                  <div className='fv-row mb-10'>
                    <InputField
                      label='New Password'
                      style={{marginBottom: '1rem'}}
                      labelClass='form-label fw-bolder text-dark fs-5'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='password'
                      name='newpassword'
                      autoComplete='off'
                    />
                  </div>

                  <div className='fv-row mb-10'>
                    <InputField
                      label='Confirm Password'
                      style={{marginBottom: '1rem'}}
                      labelClass='form-label fw-bolder text-dark fs-5'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='password'
                      name='confirmpassword'
                      autoComplete='off'
                    />
                  </div>

                  <div className='text-center d-flex justify-content-center login-container-buttons'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={isSubmitting || !isValid || loading}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
