import { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik'
import InputField from '../input-field/input-field';
import clsx from 'clsx';
import { updateSpecialRequest } from '../../services/order.service';

const OrderForm: FC<{ open: boolean; setOpen: any; apiDatatoSend: any,buttonType:string}> = ({ open, setOpen, apiDatatoSend,buttonType }) => {
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const messageSchema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
    })

    return (
        <Modal show={open} onHide={() => setOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='fw-semibold text-primary'>New Message</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={{ message: "" }}
                    validationSchema={messageSchema}
                    onSubmit={async (values: any, { setStatus, resetForm, setErrors }) => {
                        setLoading(true);
                        updateSpecialRequest(buttonType,apiDatatoSend, values.message)
                            .then((response) => {
                                if (response.error) {
                                    setErrors({ errorMessage: response.error })
                                }
                                else if (!response.error) {
                                    setSuccess("Note saved successfully");
                                    resetForm();
                                }
                            })
                            .catch(error => setErrors({ errorMessage: error })
                            )
                            .finally(() => setLoading(false));
                    }}
                >
                    {({ errors, values, handleChange, isSubmitting, isValid }: any) => (
                        <Form className='form w-100' >
                            {errors.errorMessage && (
                                <div className='mb-lg-5 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{errors?.errorMessage}</div>
                                </div>
                            )}
                            {success && (
                                <div className='mb-lg-5 alert alert-success'>
                                    <div className='alert-text text-primary font-weight-bold'>{success}</div>
                                </div>
                            )}
                            <div className='fv-row mb-10'>
                                <InputField
                                    label='Message'
                                    style={{ marginBottom: '1rem', height: 'unset' }}
                                    labelClass='form-label fs-5 fw-bolder text-dark ms-0'
                                    className={clsx('form-control form-control-lg form-control-solid')}
                                    as='textarea'
                                    rows='10'
                                    name='message'
                                    autoComplete='off'
                                    value={values.message}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='text-center d-flex justify-content-center modal-buttons'>
                                <Button
                                    variant='outline-secondary'
                                    className='btn-lg w-100 me-5'
                                    onClick={() => setOpen(false)}
                                >
                                    <span className='indicator-label'>Cancel</span>
                                </Button>
                                <Button
                                    type='submit'
                                    variant='primary'
                                    className='btn-lg w-100 ms-5'
                                    disabled={isSubmitting || !isValid || loading}
                                >
                                    {loading && (
                                        <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true' />
                                    )}
                                    <span className='indicator-label'>{loading ? 'Sending...' : 'Send'}</span>
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export { OrderForm };
