import { useAuth } from "../contexts/auth.context";
import { AuthModel } from "../models/auth/auth.model"
import { logoff } from "../services/auth.service";


const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

type requestedOptions = {
  method:string;
  headers:Headers;
  body?:string;
}


const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setUpAPI(url:string, data:{}|undefined, method: string) {
  const auth = getAuth();

  const date = new Date().getTime();

  if(auth && auth.api_token && auth.expiresAt >= date) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", auth.api_token);
  
    var requestOptions:requestedOptions = {
      method: method,
      headers: myHeaders,
    };
  
    if(data) {
      var raw = JSON.stringify(data);
      requestOptions.body = raw;
    }
  
    return fetch(url, requestOptions);
  }

  else {
    throw 'invalid user';
  }
  
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}


//THIS FUNCTION SHOULD BE HANDLED BY THE BACKEND
export function getErrorMsg(name:string) {
  let message = '';
  switch (name) {
    case "CodeMismatchException":
      message = "Invalid confirmation token.";
      break;
    case "AliasExistsException":
      message = "Alias already exists";
      break;
    case "ExpiredCodeException":
      message = "Confirmation Token is expired";
      break;
    case "InternalErrorException":
      message = "Internal Error";
      break;
    case "InvalidLambdaResponseException":
      message = "Internal Error";
      break;
    case "InvalidParameterException":
      message = "Internal Error";
      break;
    case "LimitExceededException":
      message = "Limit Exceeded";
      break;
    case "NotAuthorizedException":
      message = "User is not authorized";
      break;
    case "ResourceNotFoundException":
      message = "Internal Error";
      break;
    case "TooManyFailedAttemptsException":
      message = "Too many failed attempts";
      break;
    case "TooManyRequestsException":
      message = "Too many requests have been made.";
      break;
    case "UnexpectedLambdaException":
      message = "Internal Error";
      break;
    case "UserLambdaValidationException":
      message = "Internal Error";
      break;
    case "UserNotFoundException":
      message = "Unable to find user.";
      break;
    case "InvalidPasswordException":
      message = "Invalid Password";
      break;
    default:
      message = "Unknown error has occured.";
  }

  return message;
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
