import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Formik, Form, FormikHelpers, FormikValues} from 'formik'
import { requestCode, updatePassword } from '../../services/auth.service'
import InputField from '../input-field/input-field'
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

type FormValues = {
  verificationCode:string;
  newPassword: string;
  confirmPassword: string;
};

const forgotPasswordSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Verification Code is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'New password should contain at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], "Passwords do not match.")
})

const initialValues:FormValues = {
  verificationCode:'',
  newPassword: '',
  confirmPassword: ''
}

export function ForgotPassword2() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const {confirmation_code, userName} = useParams();


  const onSubmitHandler = async(values:FormValues, helpers: FormikHelpers<FormValues>) => {
    updatePassword(userName, confirmation_code, values.newPassword).then(data => {
      navigate('/auth/', {state: {
        type: "successful",
        message: data,
        isVisible: true,
      }});
    }).catch(err => {
      helpers.setStatus(err)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema = {forgotPasswordSchema}
      onSubmit={onSubmitHandler}
    >
      {({status, isSubmitting, isValid}) => (
        <Form className='form w-100' id='kt_login_password_reset_form'>
            <div className='mb-10'>
              <h1 className='mb-3 text-primary'>Reset Password</h1>
              <hr/>
            </div>

            {status ? (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            ) : ''}

            <div className='fv-row mb-10'>
              <InputField 
                label="Verification Code" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='Verification Code'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='verificationCode'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField 
                label="New Password" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='New Password'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='password'
                name='newPassword'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField 
                label="Confirm Password" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='Confirm Password'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='password'
                name='confirmPassword'
                autoComplete='off'
              />
            </div>

            <div className='text-center d-flex justify-content-between login-container-buttons'>
              <Link to={'/'} type='button' id='kt_sign_in_cancel' className='btn btn-lg btn-outline w-100 mb-5 text-center'>
                  <span className='indicator-label'>Cancel</span>
              </Link>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={isSubmitting || !isValid || loading}>
                {!loading && <span className='indicator-label'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </Form>
      )}
    </Formik>
  )
}
