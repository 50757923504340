import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const RequestAccessPage = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.setAttribute('type', 'text/javascript');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        //@ts-ignore
        if(window.hbspt){
            //@ts-ignore
            hbspt.forms.create({
                region: "na1",
                portalId: "23399414",
                formId: "58dd2468-d3ac-4ee7-bfa2-8aa18295b4a4",
                target: '#hubspotForm'
            });
        }
    });

    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }

  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        REQUEST ACCESS
      </PageTitle>
      <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
      <div className='card-header pt-3 pb-2 d-flex align-items-center'>
        <div className="w-100 d-grid justify-content-center">
          <div className="h-250px">
            <div className='d-grid justify-content-center' style={{height: '60%'}}>
              <img alt='Logo' src={toAbsoluteUrl('/media/ATR/images/MaestroX.png')} className='img-fluid' style={{width: '250px'}}/>
            </div>
            <hr />
          </div>
        </div>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-light fs-3 text-primary'>Request Access</span></h3>
      </div>
      
      <div className='card-body py-6'>
        <div id="hubspotForm"></div>
      </div>
    </div>
    </>
  )
}

const RequestAccessWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>REQUEST ACCESS</PageTitle>
      <RequestAccessPage />
    </>
  )
}

export {RequestAccessWrapper}
