import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, Navigate, useNavigate } from 'react-router-dom'
import {Formik, Form} from 'formik'
import InputField from '../input-field/input-field'
import {authenticate} from '../../services/auth.service'
import {useAuth} from '../../contexts/auth.context'
import {AuthModel} from '../../models/auth/auth.model'
import {UserModel} from '../../models/user/user.model'
import {getUserDetails} from '../../services/user.service'
import moment from 'moment'
import {RequestAccess} from '../request-access/request-access.component'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ForgotPasswordModal} from './forgot-password-modal.component'
var pjson = require('./../../../../package.json')

interface propState {
  type: string
  message: string
  isVisible: boolean
}

const Login = () => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation()
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [isAccessRequired, setIsAccessRequired] = useState(false)
  const navigate = useNavigate()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  useEffect(() => {
    if (location.state) {
      const {message, type} = location.state as propState
      setMessage(message)
      setType(type)
      window.history.replaceState({}, document.title)
    }
  }, [])

  return (
    <>
      <div className='col-12' style={{alignContent: 'center', display: 'grid', height: '10%'}}>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/ATR/images/MaestroX.png')}
          className='img-fluid'
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values, {setStatus, setSubmitting}) => {
          setLoading(true)
          authenticate(values.email, values.password)
            .then((data: any) => {
              const date = new Date()
              const expireDate = moment(date).add(480, 'minutes').toDate().getTime()

              const auth: AuthModel = {
                api_token: data,
                expiresAt: expireDate,
                email: values.email,
              }
              saveAuth(auth)
              const user: UserModel = {
                organization_primaryEmail: values.email,
                token: data,
              }
              setCurrentUser(user)

              getUserDetails(values.email).then((data) => {
                setCurrentUser(data)
              })
              const queryString = location.search;
              const params = new URLSearchParams(queryString);
              let returnUrlParam = null;
              const keysArray = Array.from(params.keys());
              
              for (const key of keysArray) {
                const keyLower = key.toLowerCase();
                if (keyLower === 'returnurl' || (keyLower.startsWith('auth') && keyLower.includes('returnurl'))) {
                  returnUrlParam = params.get(key);
                  break;
                }
              }

              if (returnUrlParam) {
                const returnUrl = decodeURIComponent(returnUrlParam);
                navigate(returnUrl, { replace: true });
              } else {
                navigate('/dashboard', { replace: true });
              }

            })
            .catch((err: any) => {
              saveAuth(undefined)
              setStatus(err)
              setSubmitting(false)
              setLoading(false)
            })
        }}
      >
        {({status, isSubmitting, isValid}) => (
          <Form className='form w-100' id='kt_login_signin_form'>
            <div className='mb-10'>
              <hr />
            </div>

            {status ? (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            ) : (
              ''
            )}

            {message && type === 'successful' ? (
              <div className='mb-lg-5 alert alert-success'>
                <div className='alert-text font-weight-bold text-primary'>{message}</div>
              </div>
            ) : (
              ''
            )}

            <div className='fv-row mb-10'>
              <InputField
                label='Email'
                labelClass='form-label fs-5 fw-bolder text-dark'
                placeholder='Email'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='email'
                name='email'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField
                label='Password'
                style={{marginBottom: '1rem'}}
                labelClass='form-label fw-bolder text-dark fs-5'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='password'
                name='password'
                autoComplete='off'
              />
              <Link
                to={'/forgot-password'}
                className='btn btn-link fs-5'
                style={{marginLeft: '1rem'}}
              >
                Forgot Password ?
              </Link>
            </div>

            <div className='text-center d-flex justify-content-center login-container-buttons'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={isSubmitting || !isValid || loading}
              >
                {!loading && <span className='indicator-label'>Login</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className='text-center mt-2'>
        <h5 className='mb-1 text-primary fw-semibold'>Don't have access?</h5>
        <a
          data-bs-toggle='modal'
          data-bs-target='#request-access'
          className='btn btn-lg btn-outline fs-6 cursor-pointer text-dark'
        >
          Request Access
        </a>
      </div>
      <div className='fs-6 position-relative'>
        <i style={{fontSize: '10px', position: 'absolute', bottom: '-2rem', left: '41%'}}>
          Version: {pjson.version}
        </i>
      </div>
      <RequestAccess />
      <ForgotPasswordModal />
    </>
  )
}

export default Login
