/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

type Props = {
  menuHandler: (isOpen:boolean) => void
}

const HeaderWrapper = ({menuHandler}:Props) => {
  const {config, classes, attributes} = useLayout()
  const {header} = config
  const [isOpen, setIsOpen] = useState(false);

  const handlerMenu = () => {
    setIsOpen(!isOpen);
  }

  useEffect(()=>{
    menuHandler(isOpen);
  },[isOpen])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Container */}
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}

        <div className='d-flex align-items-center d-lg-none ms-n1 me-2' title='Show aside menu' onClick={handlerMenu}>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_aside_mobile_toggle'
          >
            {!isOpen ? (
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
            ) : (
              <i className={'fa fa-close fs-1 text-white'}></i>
            )}
          </div>
        </div>
        {/* end::Aside mobile toggle */}

        {/* begin::Mobile logo */}
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0' id="logo">
          <Link to='/dashboard' className='d-lg-none'>
            <img alt='Logo' src={toAbsoluteUrl('/media/ATR/logos/justlogo.png')} className='h-30px' />
          </Link>
        </div>
        {/* end::Mobile logo */}

        {/* begin::Wrapper */}
        <div className={'d-flex align-items-stretch justify-content-between flex-lg-grow-1'}>
          {header.menu && (
            <div className='d-flex align-items-stretch w-100' id='kt_header_nav'>
              {/* <Header /> */}
              <Topbar />
            </div>
          )}
          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export default HeaderWrapper;