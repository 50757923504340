import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/app.routes'
import {ThemeModeProvider} from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { setupAxios } from './app/utils/auth.utils'
import { AuthProvider } from './app/contexts/auth.context'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic  mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject  interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeModeProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

if(process.env.REACT_APP_ENV === "prod"){
  const gtmScript = document.createElement("script");
  gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-1WDNT4GXER";
  gtmScript.async = true;
  document.body.appendChild(gtmScript);

  gtmScript.addEventListener('load', () => {
    // @ts-ignore
    if(window.dataLayer){
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];   
      // @ts-ignore
      function gtag(){dataLayer.push(arguments);}
      // @ts-ignore
      gtag('js', new Date());   
      // @ts-ignore
      gtag('config', 'G-1WDNT4GXER');
    }
  });
}