import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className=' d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='auth-container d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='mw-500px w-100 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto h-700px'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout;