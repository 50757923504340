/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './private.routes'
import {ErrorsPage} from './error.routes'
import {App} from '../App'
import {useAuth} from '../contexts/auth.context'
import {Logout} from '../components/auth/logout.component'
import {AuthPage} from './auth.routes'
import {Confirm} from '../components/auth/confirm.component'
import AuthLayout from '../pages/auth/AuthLayout'
import {RequestAccessWrapper} from '../pages/request-access/request-access.component'
import ChangePassword from '../components/auth/change-password.component'
import {ForgotPassword} from '../components/auth/forgot-password.component'
import { Invite } from '../components/invite/invite.component'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const storedAuthData = localStorage.getItem('kt-auth-react-v')
  const getRedirectPath = () => {
    if (process.env.REACT_APP_ENV === 'stg') {
      if (!currentUser && !storedAuthData) {
        const returnUrl = window.location.pathname;
        const hasAuthInReturnUrl = returnUrl.includes('/auth');
        const encodedReturnUrl = hasAuthInReturnUrl
          ? returnUrl
          : encodeURIComponent(returnUrl);
        return hasAuthInReturnUrl
          ? `/auth?returnUrl=${encodedReturnUrl}`
          : `/auth?returnUrl=${encodedReturnUrl.replace('%2F', '/')}`;
      }
      return '/auth';
    }else{
      return '/auth';
    }
    
  };

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='/' element={<Navigate to='/auth' />} />
              <Route path='invite' element={<Invite />}/>
              <Route path='requestaccess' element={<RequestAccessWrapper />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='forgotPassword/*' element={<ChangePassword />} />
              <Route element={<AuthLayout />}>
                <Route path='confirm' element={<Confirm />} />
              </Route>
              {
                
              }
              <Route path='*' element={<Navigate to={getRedirectPath()} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}