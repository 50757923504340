import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import { useAuth } from '../../../../app/contexts/auth.context'
import Moment from 'react-moment'
import {Link} from 'react-router-dom'


const itemClass = '',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-35px'

const Topbar: FC = () => {
  const {config} = useLayout();
  const date = new Date().toString();
  const {currentUser} = useAuth();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0 w-100 justify-content-between' id='kt_topbar'>
      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <span>
          <Link to='/dashboard' className='d-flex align-items-center'>
            <img
              src={toAbsoluteUrl('/media/ATR/icons/MaestroXLogo.png')}
              alt='logo'
              style={{marginTop: "-19px"}}
              className='h-38px logo'
            />
          </Link>
          </span>
        </div>
        <div className='title'>
          <h2>{currentUser?.agency_agencyName}</h2>
        </div>
      </div>
      <div className='d-flex topbar-data'>
          {/* <span className='menu-icon' style={{marginRight: '200px'}}>
            <KTSVG path='/media/ATR/icons/Flag.svg' className='svg-icon-2' />
          </span> */}
          <div className="user-info d-flex">
            <span className='username'>Hello,&nbsp;
              <a data-bs-toggle='modal'
                 data-bs-target='#user-details' 
                 className='cursor-pointer text-dark'>
                 {currentUser?.user_name}
              </a>
            </span>
            <div className='d-flex'>
              <span className='date me-0'><Moment format='MM/DD/YYYY'>{new Date(date).toISOString()}</Moment></span>
              {/* <span style={{marginTop: '-2px'}}>
                <KTSVG path='/media/ATR/icons/Icon material-expand-more.svg' className='svg-icon-6' />
              </span> */}
            </div>
          </div>
      </div>
    </div>
  )
}

export {Topbar}
