import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { BoardDates } from '../../components/board-dates/board-dates.component'
import * as d3 from 'd3'
import { BoardModel } from '../../models/boards/board.model'
import { BoardWeekModel } from '../../models/boards/boardweek.model'
import { Link } from 'react-router-dom'
import { fstat } from 'node:fs'
import jsPDF from 'jspdf'
import { CountyModel } from '../../models/boards/county.model'

const ResourcesPage = () => {

  const [NJ, setNJ]: any = useState({});
  const [PA, setPA]: any = useState({});
  const [boards, setBoards]: any[] = useState([])
  const GOOGLE_SHEETS_URL = process.env.REACT_APP_GOOGLE_SHEETS_URL;
  const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
  const SHEETS_API_KEY = process.env.REACT_APP_SHEETS_API_KEY;

  useEffect(() => {    
    const sheetURL = `${GOOGLE_SHEETS_URL}/${SPREADSHEET_ID}/values/boards!A:D?key=${SHEETS_API_KEY}`;
    const request = new XMLHttpRequest();
    request.open('GET', sheetURL, true);
    request.send(null);
    var id = 1;
    request.onreadystatechange = () => {
      if(request.readyState === 4 && request.status === 200){
        var text = request.responseText;
        var json = JSON.parse(text);
        // Should always be ['state', 'week', 'county', 'date']
        var columns = json.values[0];
        console.log(columns);
        var values = json.values.slice(1);
        var boards: BoardModel[] = [];
        values.filter((v: any) => v[0] && v[1] && v[2] && v[3]).forEach((element: any) => {
          var boardFilter = boards.filter(b => b.state == element[0]);
          if(boardFilter.length != 0){
            var board = boardFilter[0];
            var weekFilter = board.weeks.filter(w => w.currentWeek == element[1]);
            if(weekFilter.length != 0){
              var week = weekFilter[0];
              var countyFilter = week.boards.filter(c => c.county == element[2]);
              if(countyFilter.length != 0){
                var county = countyFilter[0];
                county.date = element[3];
              }
              else{
                week.boards.push({
                  id: id,
                  county: element[2],
                  date: element[3]
                })
                id += 1
              }
            }
            else{
              board.weeks.push({
                currentWeek: element[1],
                boards: [
                  {
                    county: element[2],
                    date: element[3]
                  }
                ]
              })
            }
          }
          else{
            boards.push({
              state: element[0],
              weeks: [
                {
                  currentWeek: element[1],
                  boards: [
                    {
                      id: id,
                      county: element[2],
                      date: element[3]
                    }
                  ]
                }
              ]
            });
            id += 1;
          }
        });
        setBoards(boards);
      }
    }
    
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }

  }, [])
  
  const downloadPDF = () => {
    if(boards) {
      var jspdf = new jsPDF("p", "mm", "a4");
      boards.forEach((board: BoardModel) => {
        var title = `${board.state} Board Dates`;
        jspdf.text(title, 105, 10, {align: 'center'});
        var y = 20;
        board.weeks.forEach((week: BoardWeekModel) => {
          var weekName = `Week of ${week.currentWeek}`;
          jspdf.text(weekName, 105, y, {align: 'center'});
          y += 10;
          if(y >= 290) {
            jspdf.addPage();
            y = 10;
          }
          week.boards.forEach((county: CountyModel) => {
            var countyData = `${county.county}: ${county.date}`;
            jspdf.text(countyData, 10, y);
            y += 10;
            if(y >= 290) {
              jspdf.addPage();
              y = 10;
            }
          })
        });
        jspdf.addPage();
      })
      jspdf.deletePage(jspdf.getNumberOfPages());
      jspdf.save("Board Dates.pdf");
    }

  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        RESOURCES
      </PageTitle>
      <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
      <div className='card-header pt-3 pb-2 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-light fs-3 text-primary'>Resources</span></h3>
      </div>

      <div className='card-body py-6'>
        <button style={{float: "right"}} onClick={downloadPDF} className='btn btn-sm btn-primary'>
          <i className='fa fa-download'/>
          PDF
        </button>
        <div className="board-dates col-md-12">
          {boards && boards.map((board: BoardModel, index: number) => {
            return <BoardDates key={board.state} board={board} />
          })}
        </div>
      </div>
    </div>
    </>
  )
}

const ResourcesWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>RESOURCES</PageTitle>
      <ResourcesPage />
    </>
  )
}

export {ResourcesWrapper}
