import { GridColDef } from "@mui/x-data-grid"

export interface ITableModel {
    columns: GridColDef[],
    rows: any[],
    rowCount: number,
    loading: boolean,
    page: number,
    pageSize: number,
    pagination: boolean,
    hiddenColumns: any,
    status: string,
    filterText: string|null,
    settings: {
        breadcrumb: string,
        title: string,
        showCount: boolean,
        showButton: boolean,
        buttonText: string,
        buttonLink:string,
        hideComponents:boolean,
        updatePageState: (prop:string, value:any) => void
    }
}

export let DefaultTableModel: ITableModel = {
    rows: [],
    columns: [],
    rowCount: 0,
    loading: false,
    page: 0,
    pageSize: 10,
    status: "received",
    pagination: false,
    hiddenColumns: {},
    filterText: null,
    settings: {
        breadcrumb: '',
        title: '',
        showCount: false,
        showButton: false,
        buttonText: '',
        buttonLink: '',
        hideComponents: false,
        updatePageState: () => {}
    }
}