/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  descriptionClasses?: string
  status: string
}

const TopFilterItem: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  status,
}) => {
  return (
    <div className={`card bg-${color} cursor-pointer hoverable ${className}`}>
      <div className='card-body'>
        <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-29 ms-n1`} />
        <div style={{float: 'right', marginTop: '-10px'}}>
          <KTSVG path={'/media/ATR/icons/Icon ionic-ios-expand.svg'} className={`svg-icon-${iconColor} svg-icon-6 ms-n1`} />
        </div>

        <div className={`text-${titleColor} fw-semibold fs-1 mb-2 mt-5`}>{title}</div>

        <div className={`text-decoration-underline text-${descriptionColor}`}>{description}</div>
      </div>
    </div>
  )
}

export {TopFilterItem}
