import moment from "moment";

var states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
];

export const isJSON = (str:string) => {
    try {
        return {
            result: true,
            json: JSON.parse(str)};
    } catch (error) {
        return {
            result: false
        };
    }
}

export const getState = (str:string) => {
    return states.find(state => state.includes(str));
}
export const UTCNoTime = (date:string|Date) => {
    return moment(date).format('MMM DD, YYYY');
}
export const UTCFormatted = (date:string|Date) => {
    return moment(date).format('MMM DD, YYYY HH:mm');
}
// THIS FEATURE SHOULD BE HANDLED AT THE BACKEND BUT FOR REQUEST FROM SAREL IT WAS IMPLEMENTED AT THE FRONTEND.
export const UTCtoEST = (date:string|Date) => {
    const time = new Date(date);
    var offset = -300; //Timezone offset for EST in minutes.
    const estDate = new Date(time.getTime() + offset*60*1000).toString();
    return moment(estDate).format('MMM DD, YYYY HH:mm')
}

// Function to XOR encrypt a value with a secret key
export function xorEncrypt(value: any) {
    const key = 'ATRClientSecretKey';
    let result = '';
    for (let i = 0; i < value.length; i++) {
      result += String.fromCharCode(value.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
  }
  
  // Function to XOR decrypt a value with a secret key
  export function xorDecrypt(encryptedValue : any) {
    const key = 'ATRClientSecretKey';
    let result = '';
    for (let i = 0; i < encryptedValue.length; i++) {
      result += String.fromCharCode(encryptedValue.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
  }
  
  // Function to Base64 encode a value
  export function base64Encode(value:any) {
    const encodedValue = btoa(value);
    return encodedValue;
  }
  
  // Function to Base64 decode a value
  export function base64Decode(encodedValue: any) {
    const decodedValue = atob(encodedValue);
    return decodedValue;
  }

 