import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {MenuComponent} from '../assets/ts/components'
import { MesssageForm } from '../../app/components/message-form/message-form.component'
import UserDetails from '../../app/components/user/user-details.component'
import HeaderWrapper from './components/header/HeaderWrapper'

const MasterLayout = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const onMenuHandler = (isOpen: boolean) => {
    setIsOpen(isOpen);
  }

  // const el = document.querySelector('body');
  // if(el && isOpen) {
  //   el.addEventListener("click", closeMenu);
  // }

  // function closeMenu() {
  //   setIsOpen(false);
  // }

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          {/* begin::Wrapper */}
          <div className={`wrapper d-flex flex-column flex-row-fluid ${location.pathname == '/orders/new' ? 'no-header' : ''}`} id='kt_wrapper'>
            <HeaderWrapper menuHandler={onMenuHandler} />
            
            {/* begin::Content */}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                
                <AsideDefault isOpenMobile={isOpen} />
                <div id='kt_outlet'><Outlet /></div>
              </Content>
            </div>
            {/* end::Content */}
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <UserDetails />
      {/* <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
