import { toAbsoluteUrl } from "../../_metronic/helpers";
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div id="loading" className="d-flex flex-column w-100 min-h-550px align-items-center justify-content-center">
            <span className="" style={{marginTop: '-15px'}}><ReactLoading type="spokes" color='#0090B8' width={60} /></span>
        </div>
    )
}

export default Loading;