import {AuthenticationDetails, CognitoUser} from 'amazon-cognito-identity-js'
import axios from 'axios'
import {rejects} from 'node:assert'
import {resolve} from 'node:path/win32'
import {useNavigate} from 'react-router-dom'
import {UserModel} from '../models/user/user.model'
import {getAuth, setUpAPI} from '../utils/auth.utils'
import {isJSON} from '../utils/global.utils'
import Pool from './UserPool'

const API_URL = process.env.REACT_APP_API_URL
const env = process.env.REACT_APP_ENV

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// export const authenticate = async (Username: string, Password: string) => {
//     return await new Promise((resolve, reject) => {
//       const user = new CognitoUser({
//         Username,
//         Pool,
//       });

//       const authDetails = new AuthenticationDetails({
//         Username,
//         Password,
//       });

//       user.authenticateUser(authDetails, {
//         onSuccess: (data) => {
//           resolve(data);
//         },
//         onFailure: (err) => {
//           reject(err);
//         },
//         newPasswordRequired: (data) => {
//           resolve(data);
//         },
//       });
//     });
// };

export const authenticate = async (email: string, password: string) => {
  const api = env === 'prod' ? 'signin' : 'signin-dev'

  const url = `${API_URL}/v1/services/invoker/cognito/${api}`

  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    username: email,
    password: password,
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  }

  const response = await fetch(url, requestOptions).catch((err) => {
    return err
  })

  const body = await response.text()
  const isValidJSON = isJSON(body)

  if (isValidJSON.result) {
    let errMsg = isValidJSON.json.body.split(': ')[1]
    if (errMsg == 'User does not exist.') {
      errMsg = `Incorrect username or password.`
    }
    throw errMsg
  } else {
    return body
  }
}

export const getSession = async () => {
  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser()

    if (user) {
      user.getSession((err: any, session: any) => {
        if (err) {
          window.localStorage.setItem('kt-auth-react-v', 'false')
          reject(err)
        } else {
          resolve(session)
        }
      })
    } else {
      window.localStorage.setItem('kt-auth-react-v', 'false')
      reject(new Error('Invalid User'))
    }
  })
}

export const logoff = () => {
  const user = Pool.getCurrentUser()
  if (user) {
    user.signOut()
  }
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// // Server should return object => { result: boolean } (Is Email in DB)
export const requestCode = async (email: string) => {
  return await new Promise((resolve, reject) => {
    var userData = {
      Username: email,
      Pool: Pool,
    }
    const user = new CognitoUser(userData)

    if (user) {
      user.forgotPassword({
        onSuccess: () => {
          resolve(
            'Email sent! Follow the steps in the email to complete the forgot password process.'
          )
        },
        onFailure: (err) => {
          reject(err.message)
        },
      })
    } else {
      reject(new Error('Invalid User'))
    }
  })
}

export const updatePassword = async (
  email: string | undefined,
  verificationCode: string | undefined,
  password: string
) => {
  return await new Promise((resolve, reject) => {
    var userData = {
      Username: email ? email : '',
      Pool: Pool,
    }
    const user = new CognitoUser(userData)

    if (user && verificationCode) {
      user.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          resolve('Password updated. Please login with your new credentials')
        },
        onFailure: (err) => {
          reject(err.message)
        },
      })
    } else {
      reject(new Error('Invalid User'))
    }
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export const confirm = async (email: string, code: string, newPassword: string) => {
  if (email && code && newPassword) {
    const url = `${API_URL}/v1/services/invoker/cognito/confirm`
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      email: email,
      code: code,
      newPassword: newPassword,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    }

    const response = await fetch(url, requestOptions).catch((error) => {
      return error
    })
    const body = await response.json()
    return body
  }
}

export const ResetPasswordLinkAPI = async (email: string) => {
  if (email) {
    const url = `${API_URL}/v1/services/reset-password-link`
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      username: email,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    }

    const response = await fetch(url, requestOptions).catch((error) => {
      return error
    })
    const body = await response.json()
    return body
  }
}

export const UpdatePasswordAPI = async (email: string, otp: string, password: string) => {
  if (email && otp && password) {
    const url = `${API_URL}/v1/services/update-password`

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      username: email,
      confirmationCode: otp,
      newPassword: password,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    }

    const response = await fetch(url, requestOptions).catch((error) => {
      return error
    })
    const body = await response.json()
    return body
  }
}