import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

const FaqsPage = () => {

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description='#XRS-45670'>
        FAQS
      </PageTitle>
      <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
      <div className='card-header pt-3 pb-2 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-light fs-3 text-primary'>FAQ'S</span></h3>
      </div>

      <div className='card-body py-6'></div>
    </div>
    </>
  )
}

const FaqsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <FaqsPage />
    </>
  )
}

export {FaqsWrapper}
