/* eslint-disable jsx-a11y/anchor-is-valid */
import { DataGrid, GridColumnMenu, GridColumnMenuContainer, GridColumnMenuProps, GridColumnsMenuItem, GridFilterMenuItem, GridToolbarContainer, HideGridColMenuItem, SortGridMenuItems } from '@mui/x-data-grid'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import { DefaultTableModel, ITableModel } from '../models/orders/table.model'
import { Alert } from 'react-bootstrap'
import CheckboxItem from './checkbox/checkbox'
import { MenuItem } from '@mui/material'

type Props = {
  className?: string,
  tableState: ITableModel,
  setSelectedRows?: any,
  selectedRows?:any
  checkboxSelection?:true
}

const tableStyles = {
  '&.MuiDataGrid-root': {
    fontFamily: '"Open Sans", sans-serif !important',
    fontSize: '14px !important',
    color: '#000 !important',
    border: 'none'
  },
  '.MuiDataGrid-main':{
    marginTop: '24px'
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    marginBottom: '0'
  },
  '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
    padding: '5px 5px !important',
    borderRight: 'solid 1px #AFAFAF'
  },
  '.css-yrdy0g-MuiDataGrid-columnHeaderRow, .MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-columnHeadersInner': {
    width: 'calc(100%) !important'
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#F6F6F6'
  },
  '.MuiDataGrid-columnHeadersInner': {
    width: 'calc(100% - 5.59px) !important'
  },
  '.MuiDataGrid-columnHeadersInner > [role="row"]': {
    width: '100% !important'
  },
  '.MuiDataGrid-columnHeader:last-of-type': {
    border: 'none !important'
  },
  '.MuiDataGrid-iconSeparator': {
    display: 'none'
  },
  '.MuiDataGrid-cell': {
    backgroundColor: '#FDFDFD',
    borderColor: '#DFDFDF'
  },
  '.MuiDataGrid-cell[data-field="delivered"]': {
    display: 'flex',
    justifyContent: 'center'
  },
  '.MuiDataGrid-cell[data-field="rushAction"]': {
    position: "sticky",
    right: 0,
    zIndex: 10
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700
  },
  '.MuiDataGrid-cell[data-colindex="0"]':{
    padding: '0 5px !important'
  },
  '.MuiDataGrid-cell[data-colindex="0"] .MuiDataGrid-cellContent':{
    margin: 'auto'
  }
}

const OrderTable: React.FC<Props> = ({className, tableState, selectedRows , setSelectedRows}) => {
  const {pagination, ...dpm} = DefaultTableModel;
  const [pageState, setPageState] = useState<any>(dpm);
  const filterRef = useRef<HTMLInputElement>(null);
  const [rows, setRows] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const [assigned, setAssigned] = useState<any>([])

  useEffect(() => {
    if(tableState) {
      const {settings, pagination, rows, ...newPageState} = tableState;
      setRows(rows);
      const states: any[] = [];
      const assigned: any[] = [];
      rows.forEach((row: any) => {
        var state = row.state;
        console.log(state);
        if(states.findIndex((s: any) => s === state) == -1) {
          states.push(state);
        
        }
        if(row.assignedTo && row.assignedTo.name) {
          var assignedName = row.assignedTo.name;
          if(assigned.findIndex((a: any) => a === assignedName) == -1) {
            assigned.push(assignedName);
          }
        }
      })
      setStates(states);
      setAssigned(assigned);
      setPageState (newPageState); 
    } 
  },[tableState])

  useEffect(() => {
    if(pageState && pageState.filterText && filterRef.current) {
      if(pageState.filterText !== '_reset') {
        filterRef.current.value = `${pageState.filterText}`;
      } else {
        filterRef.current.value = '';
      }
    }
  }, [pageState]);

  const onFilterHandler = (resetFilter:boolean) => {
    const newValue = !resetFilter ? filterRef.current?.value : '_reset';
    tableState.settings.updatePageState('filterText', newValue);
  }

  const onKeyPressHandler = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onFilterHandler(false);
    }
  }
  
  const handleSelectionModelChange = (params:any) => {
    setSelectedRows(params);
  };

  const filterState = (state: string) => {
    var rows = tableState.rows.filter((row: any) => {
      return row.state === state || state === "All";
    })
    setRows(rows);
  }

  const filterAssigned = (name: string) => {
    var rows = tableState.rows.filter((row: any) => {
      return row.assignedTo.name === name || name === "All";
    })
    setRows(rows);
  }

  const CustomMenu = (props: GridColumnMenuProps) => {
    return props.currentColumn.field === "state" && process.env.REACT_APP_ENV === "stg" ? (
      <GridColumnMenuContainer
        {...props}
      >
        <MenuItem onClick={(e:any) => {
          filterState('All')
          props.hideMenu(e)
        }}>All</MenuItem>
        {states.map((state: any, index: number) => (
          <MenuItem onClick={(e: any) => {
            filterState(state)
            props.hideMenu(e);
          }}>{state}</MenuItem>
        ))}
        <SortGridMenuItems onClick={props.hideMenu} column={props.currentColumn!} />
        <GridFilterMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <HideGridColMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <GridColumnsMenuItem onClick={props.hideMenu} column={props.currentColumn!} />

      </GridColumnMenuContainer>
    ) : props.currentColumn.field === "assignedTo" && process.env.REACT_APP_ENV === "stg" ? (
      <GridColumnMenuContainer
        {...props}
      >
        <MenuItem onClick={(e: any) => {
          filterAssigned('All')
          props.hideMenu(e)
        }}>All</MenuItem>
        {assigned.map((name: any, index: number) => (
          <MenuItem onClick={(e: any) => {
            filterAssigned(name)
            props.hideMenu(e)
          }}>{name}</MenuItem>
        ))}
        <SortGridMenuItems onClick={props.hideMenu} column={props.currentColumn!} />
        <GridFilterMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <HideGridColMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <GridColumnsMenuItem onClick={props.hideMenu} column={props.currentColumn!} />

      </GridColumnMenuContainer>  
    ) : (
      <GridColumnMenuContainer
        {...props}
      >
        <SortGridMenuItems onClick={props.hideMenu} column={props.currentColumn!} />
        <GridFilterMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <HideGridColMenuItem onClick={props.hideMenu} column={props.currentColumn!} />
        <GridColumnsMenuItem onClick={props.hideMenu} column={props.currentColumn!} />

      </GridColumnMenuContainer>
    )
  }

  return ( 
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div className='table-responsive h-auto'>
            <DataGrid 
              checkboxSelection={selectedRows}
              selectionModel={selectedRows}
              onSelectionModelChange={handleSelectionModelChange}
              autoHeight 
              disableSelectionOnClick 
              getRowHeight={() => 'auto'} 
              headerHeight={50}
              pagination
              paginationMode={'client'}
              rowsPerPageOptions={[10,25,50,100]}
              onPageSizeChange={(newPageSize) => tableState.settings.updatePageState('pageSize',newPageSize)}
              onPageChange={(newPage) => tableState.settings.updatePageState('page',newPage)}
              {...pageState}
              rows={rows}
              columnVisibilityModel={tableState.hiddenColumns}
              filterMode='server'
              components={tableState.settings.hideComponents ? {} : 
              { 
                ColumnMenu: CustomMenu,
                Toolbar: () => {return (<GridToolbarContainer sx={{height: 80}}>
                <div className='card-header pt-0 pb-2 d-flex align-items-center w-100'>
                  <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-light fs-3 text-primary'><span className='fw-semibold'>{`${tableState.settings.breadcrumb} / `}</span>{tableState.settings.title}</span>
                    {tableState.settings.showCount && 
                      <span className='fw-semibold rounded card-qty text-white'>{rows.length}</span>
                    }
                  </h3>
                  { process.env.REACT_APP_ENV == "fuckingshit" &&
                    <CheckboxItem model={{label: "Self assign", position: 0, isChecked: false}} onChangeHandler={() => {}}/>
                  }
                  <div className="search-box rounded border-gray w-50 d-flex align-items-center">
                    <input type='search' ref={filterRef} onKeyPress={onKeyPressHandler} />
                    <div onClick={() => onFilterHandler(false)}>
                      <KTSVG path='/media/ATR/icons/Icon feather-search.svg' className='svg-icon-5 search-icon'/>
                    </div>
                    <a id='resetFilter' className='cursor-pointer' onClick={() => onFilterHandler(true)}>Reset</a>
                    {/* <GridToolbarQuickFilter placeholder='' minLength={2} 
                    sx={{opacity: 1, padding: 0, width: '100%',
                    '.MuiInput-underline::before': {
                      border: 'none !important'
                    },
                    '.MuiSvgIcon-root[data-testid="SearchIcon"]': {
                      opacity: '0 !important'
                    },
                    '.Mui-focused::after': {
                      display: 'none !important'
                    },
                    '.MuiInput-input': {
                      marginLeft: '-1em !important'
                    }
                    }} 
                    // onChange={(e) => onFilterChangeHandler(e)} value={searchText} inputRef={filterRef} 
                    /> */}
                  </div>

                  <div className='card-toolbar'>
                    {tableState.settings.showButton && 
                        <Link to={tableState.settings.buttonLink} className='btn btn-sm btn-primary'>
                          <i className='fa fa-plus'/>
                          {tableState.settings.buttonText}
                        </Link> 
                    }
                  </div>
                </div>
              </GridToolbarContainer>)},
            }}
            sx={tableStyles}/>
        </div>
      </div>
    </div>
  )
}

export {OrderTable}
