import { CognitoUserPool } from "amazon-cognito-identity-js";

const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_CLIENT_ID } =
  process.env;

//credentials in test
const poolData = {
  UserPoolId: "us-east-1_aowV7vUr1",
  ClientId: "27n7787im55h0ukvq2rh1qnu18",
};

// Live credentials
// const poolData = {
//   UserPoolId: "us-east-1_Hs70fdLZV",
//   ClientId: "27n7787im55h0ukvq2rh1qnu18",
// };
export default new CognitoUserPool(poolData);
