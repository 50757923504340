import { useEffect } from "react";


const RequestAccess = () => {

    
  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/embed/v2.js';
    script.setAttribute('type', 'text/javascript');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @ts-ignore
        if (window.hbspt) {
            // @ts-ignore
            window.hbspt.forms.create({
                region: "na1",
                portalId: "23399414",
                formId: "58dd2468-d3ac-4ee7-bfa2-8aa18295b4a4",
                target: '#hubspotForm'
            })
        }
    });
}, []);

  return (
    <div className='modal fade' id='request-access' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content bg-white px-5'>
          <div className='modal-header'>
            <h4 className='fw-semibold text-primary'>Request Access</h4>
            <button type="button" className="close border-0 bg-white" data-dismiss="modal" aria-label="Close" data-bs-dismiss="modal" title="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className='modal-body scroll-y'>
            <div id="hubspotForm"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RequestAccess}
