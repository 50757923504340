import React, { useState, useEffect, useRef } from 'react';
import './_menu-dots.scss'; 



function ThreeDotMenu({ options,setModalOpen,setButtonType }) {
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const handleOptionClick = (option) => {

    setButtonType(option.label)
    if (option.label === 'Rundown' || option.label === 'Cover') {
      closeMenu();
      setModalOpen(true);
      option.onclick();
    } else if (option.label === 'Rush') {
      closeMenu();
      option.onclick();
    }
  };

  return (
    <div className="three-dot-menu" ref={menuRef}>
      <div className="menu-icon" onClick={toggleMenu}>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      {menuVisible && (
        <div className="menu-options">
          <span className='pb-2 menu-header'>Order</span>
          {options.map((option, index) => (
            <div
              key={index}
              className={`menu-option ${option.disable ? 'disabled' : ''}`}
              onClick={() => handleOptionClick(option)}
              disabled={option.isButtonClicked}
            
            >

              {option.label === 'Rush' ? (
              <>
                {/* <span>{option.icon}</span> */}
                <span>{option.label}</span>
              </>
            ) : (
              <span>{option.label}</span>
              
            )}

            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ThreeDotMenu;