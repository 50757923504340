import { ReactNode, useEffect, useRef, useState } from "react";
import { BoardWeekModel } from "../../models/boards/boardweek.model"
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { KTSVG } from "../../../_metronic/helpers";
export function BoardDates(props: any){
    const tableStyles = {
        '&.MuiDataGrid-root': {
          fontFamily: '"Open Sans", sans-serif !important',
          fontSize: '14px !important',
          color: '#000 !important',
          border: 'none'
        },
        '.MuiDataGrid-main':{
          marginTop: '24px'
        },
        '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
          marginBottom: '0'
        },
        '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
          padding: '5px 15px !important',
          borderRight: 'solid 1px #AFAFAF'
        },
        '.css-yrdy0g-MuiDataGrid-columnHeaderRow, .MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-columnHeadersInner': {
          width: 'calc(100%) !important'
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: '#F6F6F6'
        },
        '.MuiDataGrid-columnHeadersInner': {
          width: 'calc(100% - 5.59px) !important'
        },
        '.MuiDataGrid-columnHeadersInner > [role="row"]': {
          width: '100% !important'
        },
        '.MuiDataGrid-columnHeader:last-of-type': {
          border: 'none !important'
        },
        '.MuiDataGrid-iconSeparator': {
          display: 'none'
        },
        '.MuiDataGrid-cell': {
          backgroundColor: '#FDFDFD',
          borderColor: '#DFDFDF'
        },
        '.MuiDataGrid-cell[data-field="delivered"]': {
          display: 'flex',
          justifyContent: 'center'
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 700
        },
        '.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter': {
          float: 'right'
        },
      }
    const [cols, setCols] = useState(['county', 'date']);
    const countyGetter = (params: any) => params.getValue(params.id, 'county');
    const dateGetter = (params: any) => params.getValue(params.id, 'date');
    const filterRef = useRef<HTMLInputElement>(null);
    const columns: GridColDef[] = [
        { field: 'id', headerName: '#', flex: 1, minWidth: 28 , filterable:false, sortable:false, hide:true, hideable:false, disableColumnMenu:true},
        { field: 'county', headerName: 'County', flex: 1, minWidth: 150 , filterable:true , sortable:true, hide:false, hideable:false, disableColumnMenu:true, renderCell: (params: any) => {
            return <h5 key={props.board.state+params.value+params.field+params.id} className="board-white-text">{params.value}</h5>
        }},
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 150, filterable: true, sortable: true, hide: false, hideable: false, disableColumnMenu: true, renderCell: (params: any) => {
          return <h5 key={props.board.state+params.value+params.field+params.id} className="board-white-text">{params.value}</h5>
        }}
    ]

    const onFilterHandler = (resetFilter:boolean) => {
      const newValue = !resetFilter ? filterRef.current?.value : '_reset';
      //tableState.settings.updatePageState('filterText', newValue);
    }
  
    const onKeyPressHandler = (e:React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onFilterHandler(false);
      }
    }    

    return (
      <div key={"board_"+props.board.state} className="board-background">
          <h1 key={"h1_"+props.board.state} className="board-center">
              <span key={"span1_"+props.board.state} className="board-state-text">{props.board.state}</span><br/><span key={"span2_"+props.board.state} className="board-white-text">BOARD DATES</span>
          </h1>
          { props.board.weeks.map((week: BoardWeekModel, index: number) => {
              return (
              <div key={"main_"+props.board.state+week.currentWeek+"_"+index}>
                  <h3 key={"h3_"+props.board.state+week.currentWeek+"_"+index} className="board-week-text board-center">Week of {week.currentWeek}</h3>
                  <DataGrid 
                    sx={tableStyles}
                    key={"table_"+props.board.state+week.currentWeek+"_"+index}
                    autoHeight
                    disableSelectionOnClick={true}
                    getRowHeight={() => 'auto'}
                    pagination columns={columns} rows={week.boards}
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterValues: [],
                        },
                      }
                    }}
                    components={{
                      Toolbar: () => {
                        return (<GridToolbarQuickFilter key={"filter_"+props.board.state+week.currentWeek+"_"+index} />)
                      },
                    }}
                  ></DataGrid>
              </div>
              )
          }
          ) }
      </div>
    )
}