import {Field, useField, ErrorMessage} from 'formik';

const InputField = ({label,labelClass, className, ...props}) => {
    const [field, meta, children] = useField(props);
    const valid = meta.touched && !meta.error;
    const invalid = meta.touched && meta.error;

    return (
        <>
            <label className={labelClass} htmlFor={field.name}>{label}</label>
            {children ? (<Field
                className={`${className} ${invalid && 'is-invalid'} ${valid && 'is-valid'}`}
                autoComplete="off"
                {...field}
                {...props}
            ></Field>) : (<Field
                className={`${className} ${invalid && 'is-invalid'} ${valid && 'is-valid'}`}
                autoComplete="off"
                {...field}
                {...props}
            />)}
            
            <div>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="help is-danger"
                />
            </div>
        </>
    )
}

export default InputField;