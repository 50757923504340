import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from '../components/auth/registration.component'
import Login from '../components/auth/login.component'
import AuthLayout from '../pages/auth/AuthLayout'
import {ForgotPassword2} from '../components/auth/forgot-password2.component'
import {Confirm} from '../components/auth/confirm.component'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password2' element={<ForgotPassword2 />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}