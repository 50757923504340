import { setUpAPI } from "../utils/auth.utils";


const { REACT_APP_API_URL } = process.env;

export const getUserDetails = async (email: string) => {
    const url = `${REACT_APP_API_URL}/v1/user`;

    const response = await setUpAPI(url, undefined, 'GET').catch((err) => {
      return err;
    });

    const body = await response.json();

    return body.success.msg[0];
    
};

  

