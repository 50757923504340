import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Formik, Form, FormikHelpers} from 'formik'
import { confirm } from '../../services/auth.service'
import InputField from '../input-field/input-field'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getErrorMsg } from '../../utils/auth.utils'

type FormValues = {
  username: string,
  verificationCode:string;
  newPassword: string;
  confirmPassword: string;
};

const ConfirmSchema = Yup.object().shape({
  username: Yup.string()
    .email('Please provide a valid email')
    .required('Please provide an email'),
  verificationCode: Yup.string()
    .required('Verification Code is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'New password should contain at least 8 characters')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[!@#$%^&*()\-/_=+{};:,<.>"'\|`?\\\[\]~])/, 'Must contain at least one special character'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], "Passwords do not match.")
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[!@#$%^&*()\-/_=+{};:,<.>"'\|`?\\\[\]~])/, 'Must contain at least one special character'),
});

const initialValues:FormValues = {
  username: '',
  verificationCode:'',
  newPassword: '',
  confirmPassword: ''
}

export function Confirm () {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    if(searchParams.has("confirmation_code")) {
        let code = searchParams.get("confirmation_code") as string;
        initialValues.verificationCode =  code;
    }

    if(searchParams.has("userName")) {
        let username = searchParams.get("userName") as string;
        initialValues.username =  username;
    }

  },[searchParams])

  const onSubmitHandler = async(values:FormValues, helpers: FormikHelpers<FormValues>) => {
    setLoading(true);
    confirm(values.username, values.verificationCode, values.newPassword).then(data => {
      if(data) {
        if(data.statusCode && data.statusCode === 200) {
          navigate('/auth/', {state: {
            type: "successful",
            message: 'Account validated. Please login with your new credentials',
            isVisible: true,
          }});
        } else {
          if(data.body) {
            const body = JSON.parse(data.body);
            if(body.error) {
              const error = JSON.parse(body.error);
              helpers.setStatus(getErrorMsg(error.name));
            }
          }
        }
      }
    }).catch(err => {
      helpers.setStatus(err.message);
    }).finally(() => setLoading(false));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema = {ConfirmSchema}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
    >
      {({status, isSubmitting, isValid}) => (
        <Form className='form w-100' id='kt_verify_account_form'>
            <div className='mb-10'>
              <h1 className='mb-3 text-primary'>Verify Account</h1>
              <hr/>
            </div>

            {status ? (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            ) : ''}

            <div className='fv-row mb-10'>
              <InputField 
                label="Username" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='Username'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='email'
                name='username'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField 
                label="Verification Code" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='Verification Code'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='verificationCode'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField 
                label="New Password" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='New Password'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='password'
                name='newPassword'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-10'>
              <InputField 
                label="Confirm Password" 
                labelClass='form-label fs-5 fw-bolder text-dark' 
                placeholder='Confirm Password'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='password'
                name='confirmPassword'
                autoComplete='off'
              />
            </div>

            <div className='text-center d-flex justify-content-between login-container-buttons'>
              <Link to={'/'} type='button' id='kt_sign_in_cancel' className='btn btn-lg btn-outline w-100 mb-5 text-center'>
                  <span className='indicator-label'>Cancel</span>
              </Link>
              <button type='submit' id='kt_verify_account_submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={isSubmitting || !isValid || loading}>
                {!loading && <span className='indicator-label'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </Form>
      )}
    </Formik>
  )
}
